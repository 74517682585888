.admin-dashboard {
  margin: 20px;
  padding-top: 25px;
  min-height: 80vh;
}

h1 {
  text-align: left;
  font-size: 2em;
  margin-bottom: 20px;
}

.buttons-wrapper {
  width: 100%;
  background-color: rgba(0, 0, 0, 0.2);
  padding: 10px 0;
  box-sizing: border-box;
  border-radius: 10px;
}

.buttons-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  flex-wrap: wrap;
}

.view-toggle-btn {
  flex: 1;
  min-width: 120px;
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
}

.view-toggle-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.view-toggle-btn.active {
  background-color: #1d1fad;
}

.orders-section, .messages-section {
  margin-bottom: 20px;
}

.orders-list, .messages-list {
  list-style-type: none;
  padding: 0;
}

.order-item, .message-item {
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-bottom: 10px;
  padding: 15px;
  background-color: #2c2c2c !important;
  color: white !important;
}

.order-item.confirmed {
  opacity: 0.4;
  border-color: #c3e6cb;
  color: #27ae60 !important;
}

.order-header, .message-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
}

.button-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.action-btn {
  flex: 1 1 calc(25% - 10px);
  min-width: 100px;
  padding: 8px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.action-btn:hover {
  transform: translateY(-2px);
}

.detail-btn {
  background-color: #3498db;
  color: white !important;
}

.print-btn {
  background-color: #656765;
  color: white !important;
}

.confirm-btn {
  background-color: #27ae60;
  color: white !important;
}

.cancel-btn {
  background-color: #e74c3c;
  color: white !important;
}

.archive-btn {
  background-color: #f39c12;
  color: white !important;
}

.delete-btn {
  background-color: #e74c3c;
  color: white !important;
}

.toggle-archived-btn, .load-more-btn {
  background-color: #3498db;
  color: white !important;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  margin-top: 10px;
  border-radius: 5px;
}

.toggle-archived-btn:hover, .load-more-btn:hover {
  background-color: #2980b9;
}

.order-products {
  list-style-type: none;
  padding: 0;
}

.product-item-admin {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 7px;
  background-color: #ccc !important;
  color: #2c2c2c !important;
}

.product-info-admin {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-grow: 1;
}

.product-image-admin {
  max-width: 60px;
  max-height: 60px;
  border-radius: 5px;
  margin-left: 10px;
}

@media (max-width: 768px) {
  .product-item-admin {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .product-image-admin {
    margin-left: auto;
  }
}

.order-details p, 
.order-details span, 
.order-item p, 
.message-item p {
  margin: 2px 0;
}

.link-buttons-wrapper {
  display: flex;
  justify-content: space-around;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 20px;
}

.link-button {
  flex: 1;
  min-width: 120px;
  background-color: #3498db;
  color: white !important;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
  text-decoration: none;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.link-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.subscriptores-btn {
  background-color: #2ecc71;
}

.subscriptores-btn:hover {
  background-color: #27ae60;
}

.editor-btn {
  background-color: #e74c3c;
}

.editor-btn:hover {
  background-color: #c0392b;
}

@media (max-width: 768px) {
  .action-btn {
    flex-basis: calc(50% - 5px);
  }
}

@media (max-width: 480px) {
  .action-btn {
    flex-basis: 100%;
  }
}

.print-only {
  display: none;
}

@media print {
  body {
    background-color: white !important;
  }

  .print-only {
    display: block;
    color: #000 !important;
    background-color: none!important;
  }

  .product-item-admin,
  .order-item,
  .message-item {
    background-color: transparent !important;
    color: #000 !important;
    border: 1px solid #000 !important;
  }

  .view-toggle-btn,
  .toggle-archived-btn,
  .load-more-btn,
  .link-button {
    display: none !important;
  }
}

.filters {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.filter-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2c2c2c;
  color: white !important;
  transition: border-color 0.3s;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
}

.filter-input::placeholder {
  color: #aaa;
}

.filter-input:focus {
  border-color: #3498db;
  outline: none;
}

@media (max-width: 768px) {
  .filter-input {
    max-width: 100%;
  }
}

.generate-fake-orders {
  margin: 20px;
  padding-top: 25px;
  text-align: center;
}

.generate-button {
  background-color: #3498db;
  color: white !important;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.generate-button:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}








/* Tracking Info Styles */
.tracking-info {
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-top: 20px;
}

.tracking-info input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  transition: border-color 0.3s;
}

.tracking-info input:focus {
  border-color: #3498db;
  outline: none;
}

.save-btn {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.3s;
}

.save-btn:disabled {
  cursor: not-allowed;
  opacity: 0.6;
}

.save-btn.btn-blue {
  background-color: #3498db;
  color: white;
}

.save-btn.btn-blue:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.save-btn.btn-green {
  background-color: #2ecc71;
  color: white;
}

.save-btn.btn-green:hover {
  background-color: #27ae60;
  transform: translateY(-2px);
}
