.imprimir-ticket-content {
    padding: 20px;
    font-size: 14px;
    color: #000;
    background-color: #fff;
    width: 100%;
    box-sizing: border-box;
    max-width: 100%; /* Asegura que no exceda el ancho de la página */
    page-break-inside: avoid; /* Evita que el contenido se divida entre páginas */
}
  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .logo {
    width: 150px;
  }
  
  .company-info {
    text-align: right;
    font-size: 12px;
  }
  
  .order-info, .products-info, .summary-info {
    margin-bottom: 20px;
  }
  
  .info-item {
    font-weight: bold;
  }
  
  .product-item {
    border-bottom: 1px solid #ddd;
    padding: 10px 0;
    display: flex; /* Asegura que cada item esté alineado en una sola línea */
  }
  
  .product-info {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
  
  .product-info p {
    margin: 0;
    padding: 0;
  }
  
  .summary-info p {
    font-weight: bold;
  }
  
  .print-button {
    background-color: #2ecc71;
    color: white;
    border: none;
    padding: 10px 20px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 16px;
    margin-top: 20px;
    transition: background-color 0.3s, transform 0.3s;
  }
  
  .print-button:hover {
    background-color: #27ae60;
    transform: translateY(-2px);
  }
  
  @media print {
    body * {
      visibility: hidden;
    }
  
    .imprimir-ticket-content, .imprimir-ticket-content * {
      visibility: visible;
    }
  
    .imprimir-ticket-content {
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
    }
  }
  