.sales-report {
  margin: 20px auto;
  padding-top: 25px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-sizing: border-box;
  max-width: 1200px;
  text-align: center;
}

.sales-report h2 {
  margin-bottom: 20px;
  color: #f0f0f0;
}

.filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.filter-input {
  padding: 10px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #2c2c2c;
  color: #e0e0e0;
}

.filter-buttons {
  display: flex;
  gap: 10px;
}

.filter-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
}

.filter-button:hover {
  background-color: #2980b9;
}

.sales-section {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #2c2c2c;
}

.sales-section h3 {
  color: #f0f0f0;
  margin-bottom: 10px;
}

.button-group {
  display: flex;
  gap: 10px;
  justify-content: center;
  margin-bottom: 10px;
}

.summary-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.569);
  padding: 20px;
  border-radius: 10px;
  margin-top: 20px;
 
}

.sales-section ul {
  list-style-type: none;
  padding: 0;
}

.sales-section li {
  padding: 10px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  margin-bottom: 5px;
  border-radius: 5px;
  display: flex;
  justify-content: space-between;
}

.sales-section li:hover {
  background-color: #3a3a3a;
}

.product-list {
  text-align: left;
  padding: 0 20px;
}

.product-model {
  flex: 2;
}

.product-quantity {
  flex: 1;
  text-align: right;
}

.product-unit-cost {
  flex: 2;
  text-align: right;
}

.product-cost {
  flex: 2;
  text-align: right;
}

.total-cost {
  margin-top: 20px;
}

.sale-summary {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.order-details,
.sale-details {
  margin-top: 10px;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
  text-align: left;
}

.product-image {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin-top: 5px;
}

.print-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #3cba54;
  color: white;
  border: none;
  cursor: pointer;
  font-size: 16px;
}

.print-button:hover {
  background-color: #35a344;
}

.chart-container {
  width: 100%;
  max-width: 300px; /* Reduce the size by setting a max-width */
  margin: 20px;
}

.totals {
  flex: 1;
  margin-left: 20px;
}

.half-size {
  max-width: 300px; /* Reduce the size of the half-size class */
}

@media (max-width: 768px) {
  .summary-container {
    flex-direction: column;
    align-items: center;
  }

  .chart-container {
    max-width: 100%;
    margin-bottom: 20px;
  }

  .sales-section li {
    flex-direction: column;
    text-align: left;
  }

  .product-model,
  .product-quantity,
  .product-unit-cost,
  .product-cost {
    flex: none;
    text-align: left;
  }

  .product-quantity,
  .product-unit-cost,
  .product-cost {
    margin-top: 5px;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color: black !important;
    background: none !important;
  }

  .print-button {
    display: none !important;
  }

  .sales-report {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    color: black !important;
    background: none !important;
    z-index: 9999 !important;
  }

  .sales-section {
    page-break-inside: avoid !important;
    z-index: 9999 !important;
  }

  .order-details,
  .sale-details {
    margin-top: 10px !important;
    padding: 10px !important;
    border-radius: 5px !important;
    color: black !important;
    background: none !important;
    z-index: 9999 !important;
  }

  .sales-section li {
    background: none !important;
    border: 1px solid black !important;
    color: black !important;
    z-index: 9999 !important;
  }

  .sales-section h4 {
    color: black !important;
    z-index: 9999 !important;
  }

  .product-list {
    page-break-inside: avoid !important;
  }
}

