.subscription-form {
  margin-top: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  border: 1px solid #4CAF50;
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
}

.subscription-form h2 {
  width: 100%;
  text-align: center;
  color: #333;
  margin-bottom: 20px;
  font-size: 1.5em;
}

.subscription-input {
  padding: 15px;
  font-size: 1em;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-right: 10px;
  width: 300px;
  max-width: 100%;
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  text-align: center; /* Centrar el placeholder horizontalmente */
  line-height: 1.5em; /* Asegura que el texto del placeholder esté centrado verticalmente */
}

.subscription-button {
  padding: 15px 25px;
  font-size: 1em;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  height: 50px;
  box-sizing: border-box;
}

.subscription-button:hover {
  background-color: #45a049;
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.2);
}

@media (max-width: 768px) {
  .subscription-form {
    flex-direction: column;
  }

  .subscription-input {
    margin-right: 0;
    margin-bottom: 10px;
    width: 100%;
  }

  .subscription-button {
    width: 100%;
  }
}
.label{
  color: #304df6;
}