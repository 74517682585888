/* Estilo para el contenedor principal que mantiene el QR centrado y evita que el footer se suba */
.qr-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: calc(100vh - 100px); /* Ajusta el espacio del footer */
    padding: 20px;
    background-color: #f0f0f0; /* Fondo claro para contrastar con el contenedor blanco */
  }
  
  .qr-container {
    background-color: #ffffff; /* Contenedor blanco */
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center; /* Centra el contenido dentro del contenedor */
  }
  
  .qr-logo {
    width: 320px!important; /* Ajusta el tamaño del logo */
    max-width: 100%;
    margin-bottom: 20px; /* Espacio entre el logo y el QR */
    object-fit: contain; /* Asegura que el logo mantenga sus proporciones */
  }
  
  .qr-code {
    width: 100%;
    max-width: 256px;
    height: auto;
    margin-bottom: 15px;
  }
  
  .qr-text {
    font-size: 16px;
    color: #333;
  }
  
  /* Estilo para pantallas móviles */
  @media (max-width: 600px) {
    .qr-logo {
      width: 60px; /* Ajusta el tamaño del logo en pantallas pequeñas */
      margin-bottom: 10px; /* Reduce el espacio entre el logo y el QR */
    }
  
    .qr-code {
      max-width: 200px;
    }
  
    .qr-text {
      font-size: 14px;
    }
  }
  