.home {
  text-align: center;
  margin: 20px;
  padding-top: 65px;
}

.home-logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 70vh;
  width: 100%;
  background-color: none;
}

.home-logo {
  max-width: 50%;
  max-height: 50%;
  filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.7))
          drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.5))
          drop-shadow(0px 0px 75px rgba(255, 255, 255, 0.3));
  animation: majestic-aura 30s infinite ease-in-out;
}

.home-images {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-content: center;
}

.home-image-item {
  position: relative;
  padding-top: 100%;
}

.home-image-item .remove-button {
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  border: none;
  cursor: pointer;
  padding: 5px;
  border-radius: 5px;
  z-index: 95;
}

.home-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 10px;
}

.home-social {
  margin-top: 20px;
}

.social-input {
  display: block;
  width: 100%;
  max-width: 400px;
  margin: 10px auto;
  padding: 10px;
  font-size: 1em;
  border-radius: 5px;
  border: 1px solid #ccc;
}

.social-links {
  display: flex;
  justify-content: center;
  gap: 15px;
}

.social-logo {
  width: 30px;
  height: 30px;
}

.save-button {
  margin-top: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.save-button:hover {
  background-color: #45a049;
}

.add-image-button {
  margin-top: 20px;
  background-color: #008CBA;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
}

.add-image-button:hover {
  background-color: #007bb5;
}

.share-app-container {
  margin-top: 20px;
  text-align: center;
}

.share-button {
  background-color: #304df6;
  color: #fff;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 18px;
  transition: background-color 0.3s ease;
}

.share-button:hover {
  background-color: #0a399c;
}

.copy-success {
  margin-top: 10px;
  color: #28a745;
  font-size: 14px;
}

@keyframes majestic-aura {
  0%, 100% {
    filter: drop-shadow(0px 0px 15px rgba(255, 255, 255, 0.8))
            drop-shadow(0px 0px 30px rgba(255, 255, 255, 0.6))
            drop-shadow(0px 0px 45px rgba(255, 255, 255, 0.4));
  }
  20% {
    filter: drop-shadow(0px 0px 30px rgba(255, 255, 255, 1))
            drop-shadow(0px 0px 60px rgba(255, 255, 255, 0.8))
            drop-shadow(0px 0px 90px rgba(255, 255, 255, 0.6));
  }
  40% {
    filter: drop-shadow(0px 0px 45px rgba(255, 255, 255, 1))
            drop-shadow(0px 0px 90px rgba(255, 255, 255, 0.9))
            drop-shadow(0px 0px 135px rgba(255, 255, 255, 0.7));
  }
  60% {
    filter: drop-shadow(0px 0px 25px rgba(255, 255, 255, 0.6))
            drop-shadow(0px 0px 50px rgba(255, 255, 255, 0.4))
            drop-shadow(0px 0px 75px rgba(255, 255, 255, 0.2));
  }
  80% {
    filter: drop-shadow(0px 0px 35px rgba(255, 255, 255, 0.9))
            drop-shadow(0px 0px 70px rgba(255, 255, 255, 0.7))
            drop-shadow(0px 0px 105px rgba(255, 255, 255, 0.5));
  }
}

@media (max-width: 768px) {
  .home-logo-container {
    height: 77vh;
  }

  .home-logo {
    max-width: 70%;
    max-height: 70%;
  }
}
