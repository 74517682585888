.receipt {
    text-align: left;
    margin: 20px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
    width: 100%;
    max-width: 210mm; /* A4 width */
    box-sizing: border-box;
  }
  
  .receipt .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .receipt .logo {
    width: 250px;
  }
  
  .receipt .company-info {
    text-align: right;
    font-size: 0.9em;
  }
  
  .receipt .order-info {
    margin-bottom: 20px;
  }
  
  .receipt .order-info p,
  .receipt .products-info p {
    margin: 5px 0;
  }
  
  .receipt .products-info {
    margin-top: 20px;
  }
  
  .receipt .products-info ul {
    padding-left: 20px;
  }
  
  .receipt .products-info ul li {
    list-style-type: disc;
  }
  
  .receipt-buttons {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
  }
  
  @media print {
    .receipt {
      width: 100%;
      margin: 0;
      padding: 0;
      border: none;
    }
  
    .receipt-buttons {
      display: none;
    }
  
    .page {
      page-break-after: always;
    }
  }
  