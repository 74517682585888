/* General container for the chart */
.wallStreetChart-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #2a2a2a;
  border-radius: 12px;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.5);
  margin-top: 35px;
}

/* Title for the chart */
.wallStreetChart-container h3 {
  text-align: center;
  font-size: 26px;
  color: #f0f0f0;
  margin-bottom: 20px;
  letter-spacing: 1px;
}

/* Container for the filter inputs */
.wallStreetChart-filters {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
  flex-wrap: wrap;
  gap: 10px; /* Control of spacing */
}

.wallStreetChart-filterInput {
  flex: 1;
  min-width: 150px;
  padding: 12px;
  font-size: 16px;
  background-color: #3c3c3c;
  border: 1px solid #555555;
  border-radius: 6px;
  color: #f0f0f0;
  box-sizing: border-box;
  transition: border-color 0.3s ease;
}

.wallStreetChart-filterInput:focus {
  border-color: #8884d8; /* Color matching the chart */
}

.wallStreetChart-filterInput::placeholder {
  color: #aaaaaa;
}

/* Ensure inputs stack on small screens */
@media (max-width: 768px) {
  .wallStreetChart-filters {
    flex-direction: column;
    align-items: stretch;
  }

  .wallStreetChart-filterInput {
    margin-bottom: 10px;
    width: 100%;
  }
}

/* Chart container */
.responsive-container {
  width: 100%;
  height: 400px;
}

/* Zoom controls */
.wallStreetChart-zoom {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.wallStreetChart-zoom label {
  font-size: 16px;
  margin-right: 10px;
  color: #f0f0f0;
}

.wallStreetChart-zoom input[type="range"] {
  max-width: 400px;
  flex: 1;
  margin: 0 10px;
}

.wallStreetChart-zoom input[type="range"]::-webkit-slider-thumb,
.wallStreetChart-zoom input[type="range"]::-moz-range-thumb {
  height: 15px;
  width: 15px;
  background-color: #8884d8;
  border-radius: 50%;
  cursor: pointer;
}

@media (max-width: 768px) {
  .wallStreetChart-zoom {
    flex-direction: column;
  }

  .wallStreetChart-zoom input[type="range"] {
    margin-top: 10px;
    width: 100%;
  }
}

/* Tooltip styles */
.recharts-tooltip-wrapper {
  background-color: rgba(50, 50, 50, 0.8);
  padding: 10px;
  border-radius: 8px;
  color: #f0f0f0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.5);
}

.custom-tooltip .label {
  font-size: 14px;
  margin-bottom: 5px;
  font-weight: bold;
}

.custom-tooltip p {
  margin: 2px 0;
  font-size: 14px;
}

.custom-tooltip .total {
  margin-top: 5px;
  font-weight: bold;
  font-size: 15px;
}

/* Custom styling for the chart grid */
.recharts-cartesian-grid line {
  stroke: #444444;
}

/* Bar styling to make candles more visually appealing */
.recharts-bar-rectangle {
  stroke-width: 1;
  fill-opacity: 0.85;
}

.recharts-xAxis .recharts-cartesian-axis-tick-value,
.recharts-yAxis .recharts-cartesian-axis-tick-value {
  fill: #f0f0f0;
  font-size: 14px;
}

.recharts-cartesian-axis-line {
  stroke: #666666;
}

.recharts-cartesian-axis-tick {
  stroke: #666666;
}

/* Responsive adjustments */
@media (max-width: 768px) {
  .wallStreetChart-container h3 {
    font-size: 22px;
  }

  .responsive-container {
    height: 300px;
  }
}

/* Legend styles */
.model-legend {
  display: flex;
  justify-content: center;
  margin: 20px 0;
  flex-wrap: wrap;
  gap: 15px;
}

.model-legend-item {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.model-legend-color {
  width: 20px;
  height: 20px;
  margin-right: 8px;
  border-radius: 4px;
}

.model-legend-item span {
  color: #f0f0f0;
  font-size: 16px;
}

@media (max-width: 768px) {
  .model-legend-item {
    margin-bottom: 10px;
  }

  .model-legend-color {
    width: 16px;
    height: 16px;
  }
}
