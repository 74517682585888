/* ContactForm.css */
* {
  box-sizing: border-box;
}

.contact-form {
  background-color: #1a1a1a;
  color: #fff;
  padding: clamp(20px, 5vw, 30px);
  border-radius: 10px;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  max-width: 600px;
  width: 90%;
  margin: 40px auto;
  border: 1px solid #333;
  padding-top: 65px;
}

.contact-form h2 {
  text-align: center;
  margin-bottom: 25px;
  color: #4caf50;
  font-size: clamp(24px, 5vw, 32px);
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  font-size: clamp(14px, 3vw, 16px);
}

.form-group input,
.form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #444;
  border-radius: 5px;
  background-color: #262626;
  color: #fff;
  transition: all 0.3s ease;
  font-size: clamp(14px, 3vw, 16px);
}

.form-group input:focus,
.form-group textarea:focus {
  border-color: #4caf50;
  box-shadow: 0 0 5px rgba(76, 175, 80, 0.5);
  outline: none;
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

button[type="submit"] {
  display: block;
  width: 100%;
  padding: 12px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: clamp(16px, 4vw, 18px);
  cursor: pointer;
  transition: all 0.3s ease;
}

button[type="submit"]:hover {
  background-color: #45a049;
  transform: translateY(-2px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

button[type="submit"]:active {
  transform: translateY(0);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

@media (max-width: 480px) {
  .contact-form {
    padding: 15px;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group input,
  .form-group textarea {
    padding: 10px;
  }
  
  button[type="submit"] {
    padding: 10px;
  }
}

/* Estilos para la animación de carga */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading {
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 3px solid rgba(255,255,255,.3);
  border-radius: 50%;
  border-top-color: #fff;
  animation: spin 1s ease-in-out infinite;
  margin-left: 10px;
}