.invoice-generator {
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f7f7f7;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  }
  
  .invoice-generator-title {
    text-align: center;
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }
  
  .invoice-generator-section {
    margin-bottom: 20px;
  }
  
  .invoice-generator-section label {
    display: block;
    font-weight: bold;
    margin-bottom: 5px;
    color: #333;
  }
  
  .invoice-generator-section select,
  .invoice-generator-section input {
    width: 100%;
    padding: 8px;
    margin-bottom: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 16px;
  }
  
  .invoice-generator-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 16px;
    width: 100%;
  }
  
  .invoice-generator-button:hover {
    background-color: #45a049;
  }
  
  .invoice-generator-product-list {
    list-style: none;
    padding: 0;
    margin: 0;
    margin-bottom: 20px;
  }
  
  .invoice-generator-product-item {
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 10px;
    color: #333;
  }
  
  .invoice-generator-error {
    color: red;
    margin-top: 20px;
    text-align: center;
  }
  
  .invoice-generator-success {
    color: green;
    margin-top: 20px;
    text-align: center;
  }
  