.footer {
  background-color: #000000;
  padding: 20px;
  text-align: center;
  color: aliceblue;
  transition: opacity 0.5s ease-in-out; /* Transición suave */
  opacity: 0; /* Inicialmente oculto */
}

.footer.visible {
  opacity: 1; /* Hacer visible */
}

.footer.hidden {
  opacity: 0; /* Mantener oculto */
}

.footer-contact {
  margin-bottom: 10px;
}

.footer-contact h4 {
  margin-bottom: 10px;
}

.footer-contact p {
  margin: 5px 0;
}

.footer-developer {
  font-size: 0.9em;
  color: #888;
}

.footer-logos-container {
  background-color: white;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: opacity 0.5s ease-in-out; /* Transición suave */
  opacity: 0; /* Inicialmente oculto */
}

.footer-logos-container.visible {
  opacity: 1; /* Hacer visible */
}

.footer-logos-container.hidden {
  opacity: 0; /* Mantener oculto */
}

.footer-logos {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
  justify-content: center;
}
