.subscriber-list {
  margin: 20px auto;
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f9f9f9;
  max-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  color: #333 !important;
}

.subscriber-list h2 {
  margin-bottom: 20px;
  text-align: center;
  color: #333;
  font-size: 1.5em;
}

.subscriber-list .table-wrapper {
  overflow-x: auto;
}

.subscriber-list table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.subscriber-list th,
.subscriber-list td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
}

.subscriber-list th {
  background-color: #4caf50;
  color: white;
  text-align: center;
}

.subscriber-list tr:nth-child(even) {
  background-color: #f2f2f2;
}

.subscriber-list tr:hover {
  background-color: #ddd;
}

.subscriber-list td {
  text-align: center;
  vertical-align: middle;
}

@media (max-width: 768px) {
  .subscriber-list {
    padding: 10px;
  }
}
