/* LocalSales.css */
.local-sales {
  margin: 20px;
  padding-top: 25px;
}

.local-sales-title {
  text-align: left;
  font-size: 2em;
  margin-bottom: 20px;
}

.local-sales-search-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2c2c2c;
  color: white;
  transition: border-color 0.3s;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.local-sales-search-input::placeholder {
  color: #aaa;
}

.local-sales-search-input:focus {
  border-color: #3498db;
  outline: none;
}

.local-sales-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.local-sales-product-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #2c2c2c;
  color: white;
  flex: 1;
  min-width: 250px;
  box-sizing: border-box;
}

.local-sales-product-image {
  width: 60px;
  height: 60px;
  object-fit: cover;
  border-radius: 5px;
  margin-right: 10px;
}

.local-sales-product-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.local-sales-quantity-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #2c2c2c;
  color: white;
  transition: border-color 0.3s;
  margin-top: 5px;
}

.local-sales-quantity-input:focus {
  border-color: #3498db;
  outline: none;
}

.local-sales-selected-title {
  font-size: 1.5em;
  margin-top: 20px;
}

.local-sales-selected-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.local-sales-selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #2c2c2c;
  color: white;
  margin-bottom: 10px;
}

.local-sales-selected-item-actions {
  display: flex;
  align-items: center;
}

.local-sales-product-total {
  margin-right: 10px;
  font-weight: bold;
}

.local-sales-price-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #2c2c2c;
  color: white;
  width: 70px;
  margin-right: 10px;
  text-align: right;
}

.local-sales-remove-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.local-sales-remove-btn:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.local-sales-total {
  font-size: 1.5em;
  margin-bottom: 20px;
}

.local-sales-customer-info {
  margin-bottom: 20px;
}

.local-sales-customer-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2c2c2c;
  color: white;
  transition: border-color 0.3s;
  width: 100%;
  margin-bottom: 10px;
}

.local-sales-customer-input::placeholder {
  color: #aaa;
}

.local-sales-customer-input:focus {
  border-color: #3498db;
  outline: none;
}

.local-sales-additional-fields-btn {
  width: 100%;
  padding: 10px;
  font-size: 16px;
  color: #fff;
  background-color: #007bff;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  margin-bottom: 10px;
}

.local-sales-additional-fields-btn:hover {
  background-color: #0056b3;
}

.local-sales-submit-container {
  margin-top: 20px;
  text-align: center;
}

.local-sales-submit-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.local-sales-submit-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.local-sales-submit-btn-disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

/* Estilos para el modal */
.local-sales-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.local-sales-modal-content {
  background-color: #2c2c2c;
  padding: 20px;
  border-radius: 5px;
  max-width: 500px;
  width: 100%;
  color: white;
  max-height: 80vh; /* Altura máxima del contenido del modal */
  overflow-y: auto; /* Habilitar el desplazamiento vertical si el contenido es demasiado alto */
}
.local-sales-modal-content h2 {
  margin-top: 0;
}

.local-sales-modal-content ul {
  list-style-type: none;
  padding: 0;
}

.local-sales-modal-content li {
  margin-bottom: 5px;
}

.local-sales-modal-close {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  margin-top: 20px;
}

.local-sales-modal-close:hover {
  background-color: #2980b9;
}


/* Clase para el input de descuento */
.local-sales-discount-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.local-sales-discount-container label {
  font-size: 1.2em;
  margin-right: 10px;
  color: white;
}

.local-sales-discount-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2c2c2c;
  color: white;
  transition: border-color 0.3s;
  width: 100px;
  text-align: right;
}

.local-sales-discount-input:focus {
  border-color: #3498db;
  outline: none;
}

