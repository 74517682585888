.discount-config {
    text-align: center;
    margin: 20px;
    padding-top: 25px;
  }
  
  .discount-config-title {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .discount-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 20px;
  }
  
  .discount-input {
    width: 50%;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    text-align: center;
  }
  
  .discount-button {
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 10px;
  }
  
  .discount-button:hover {
    background-color: #0056b3;
  }
  
  .discount-list {
    margin-top: 20px;
    padding: 20px;
    background-color: #f0f0f0;
    color: #4e4b4b;
    border-radius: 5px;
  }
  
  .discount-list-title {
    font-size: 20px;
    margin-bottom: 10px;
  }
  
  .discount-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    padding: 10px;
    background-color: #e6e6e6;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .discount-detail {
    margin: 5px 0;
  }
  
  .discount-delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    margin-top: 10px;
  }
  
  .discount-delete-button:hover {
    background-color: #c82333;
  }
  