/* Estilos Generales */
.stock-management {
  margin: 20px;
  padding-top: 25px;
}

.stock-management-title {
  text-align: left;
  font-size: 2em;
  margin-bottom: 20px;
}

.stock-management-search-input {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 16px;
  background-color: #2c2c2c;
  color: white;
  transition: border-color 0.3s;
  width: 100%;
  max-width: 300px;
  box-sizing: border-box;
  margin-bottom: 20px;
}

.stock-management-search-input::placeholder {
  color: #aaa;
}

.stock-management-search-input:focus {
  border-color: #3498db;
  outline: none;
}

.stock-management-products-list {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
}

.stock-management-product-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #2c2c2c;
  color: white;
  flex: 1;
  min-width: 300px; /* Asegurar un ancho mínimo */
}

.stock-management-product-image {
  max-width: 60px;
  max-height: 60px;
  border-radius: 5px;
  margin-right: 10px;
}

.stock-management-product-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

.stock-management-quantity-input {
  padding: 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 14px;
  background-color: #2c2c2c;
  color: white;
  transition: border-color 0.3s;
  margin-top: 5px;
}

.stock-management-quantity-input:focus {
  border-color: #3498db;
  outline: none;
}

.stock-management-selected-title {
  font-size: 1.5em;
  margin-top: 20px;
}

.stock-management-selected-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.stock-management-selected-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #2c2c2c;
  color: white;
  margin-bottom: 10px;
}

.stock-management-remove-btn {
  background-color: #e74c3c;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 14px;
  transition: background-color 0.3s, transform 0.3s;
}

.stock-management-remove-btn:hover {
  background-color: #c0392b;
  transform: translateY(-2px);
}

.stock-management-submit-container {
  text-align: right;
}

.stock-management-submit-btn {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  border-radius: 5px;
  font-size: 16px;
  font-weight: bold;
  transition: background-color 0.3s, transform 0.3s;
}

.stock-management-submit-btn:hover {
  background-color: #2980b9;
  transform: translateY(-2px);
}

.stock-management-submit-btn-disabled {
  background-color: #388E3C;
  cursor: not-allowed;
}

.stock-management-summary-title {
  font-size: 1.5em;
  margin-top: 20px;
}

.stock-management-summary-list {
  list-style-type: none;
  padding: 0;
  margin-bottom: 20px;
}

.stock-management-summary-item {
  display: flex;
  align-items: center;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
  background-color: #2c2c2c;
  color: white;
  margin-bottom: 10px;
}

.stock-management-summary-image {
  max-width: 60px;
  max-height: 60px;
  border-radius: 5px;
  margin-right: 10px;
}

.stock-management-summary-info {
  display: flex;
  flex-direction: column;
  flex: 1;
}

/* Estilos Responsivos */
@media (max-width: 768px) {
  .stock-management-products-list {
    flex-direction: column;
  }

  .stock-management-product-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .stock-management-summary-item {
    flex-direction: column;
    align-items: flex-start;
  }
}

/* Estilos Específicos para la Tabla */
.stock-management-table {
  width: 100%;
  overflow-x: auto;
  display: block;
}

.stock-management-table table {
  width: 100%;
  border-collapse: collapse;
}

.stock-management-table th, .stock-management-table td {
  padding: 10px;
  border: 1px solid #ccc;
  text-align: left;
  background-color: #2c2c2c;
  color: white;
}

.stock-management-table th {
  background-color: #3498db;
}

.stock-management-table td {
  white-space: nowrap;
}
