.spinner-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.718); /* Fondo semi-transparente */
  z-index: 9999; /* Asegura que esté por encima de otros elementos */
}

.spinner {
  width: 100px;
  height: 100px;
  position: relative;
  animation: spin 2s linear infinite;
}

.pentagon {
  width: 100%;
  height: 100%;
  background-color: transparent;
  border: 3px solid #eb24d1;
  clip-path: polygon(50% 0%, 100% 38%, 82% 100%, 18% 100%, 0% 38%);
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}