.products-container {
  margin: 0 auto; /* Centra el contenedor horizontalmente */
  padding: 0; /* Ajuste de padding */
  width: 100%; /* Asegura que ocupe el 100% del ancho */
  max-width: 100%; /* Evita que el ancho máximo limite el contenido */
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-sizing: border-box;
  min-height: 35vh;
  margin-top: 20px;
  padding-top: 70px;
}

.products-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #f0f0f0;
}

.category-buttons-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 25px;
  margin-bottom: 20px;
}

.category-button {
  padding: 10px 15px;
  border: none;
  border-radius: 5px;
  background-color: #ffffff;
  color: #000000;
  cursor: pointer;
  transition: background-color 0.3s;
  box-shadow: 0px 6px 15px 0px rgba(179, 190, 195, 0.636);
}

.category-button:hover {
  background-color: #000000;
  color:#ffffff;
  box-shadow: 0px 6px 15px 0px rgba(179, 190, 195, 0.636);
}

.dropdown-container {
  position: relative;
  display: inline-block;
}

.dropdown-content-container {
  display: none; /* Oculto por defecto */
  position: absolute;
  min-width: 220px;
  margin-bottom: 1px;
  z-index: 1;
}

.dropdown-content-container.show {
  display: block; /* Mostrar cuando tenga la clase "show" */
}


.dropdown-content-button {
  width: 100%;
  padding: 12px;
  background-color: #ffffff;
  color: #000000;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s;
}

.dropdown-content-button:hover {
  background-color: #9b9b9b;
  color: #ffffff;
  box-shadow: 0px 8px 16px 0px rgba(179, 190, 195, 0.936);
}

.dropdown-container:hover .dropdown-content-container {
  display: block;
}

.modal-overlay-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 10000; /* Ensures modal is on top */
}

.modal-content-container {
  background: #1e1e1e;
  padding: 20px;
  border-radius: 5px;
  width: 90%;
  max-width: 500px;
  max-height: 90%;
  overflow-y: auto;
  z-index: 10001;
}

.modal-content-container img {
  width: 100%;
  height: auto;
  border-radius: 5px;
  margin-bottom: 20px;
 
}

.product-form-container {
  display: flex;
  flex-direction: column;
}

.form-group-container {
  margin-bottom: 15px;
}

.form-label {
  margin-bottom: 5px;
  color: #e0e0e0;
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 8px;
  border: 1px solid #444;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.image-preview-container {
  display: flex;
  gap: 10px;
  flex-wrap: wrap;
}

.image-preview-wrapper {
  position: relative;
}

.image-preview {
  width: 100px;
  height: 100px;
  object-fit: cover;
  border-radius: 5px;
}

.remove-image-button {
  position: absolute;
  top: 5px;
  right: 5px;
  background: red;
  color: white;
  border: none;
  border-radius: 50%;
  cursor: pointer;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
}

.form-actions-container {
  display: flex;
  gap: 10px;
  justify-content: flex-end;
}

.form-action-button {
  padding: 10px 20px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.form-action-button.submit {
  background-color: #28a745;
  color: white;
}

.form-action-button.submit:hover {
  background-color: #0d571e;
}

.form-action-button.cancel {
  background-color: #dc3545;
  color: white;
}

.form-action-button.cancel:hover {
  background-color: #8b1823;
}

.ball-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  justify-content: center;
}

.product-item-container {
  background: #00000066;
  padding: 15px;
  border-radius: 10px;
  width: calc(20% - 40px); /* 5 products per row */
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.5);
  transition: transform 0.3s, box-shadow 0.3s;
}


.product-item-container:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 12px rgba(0, 0, 0, 0.7);
}

.product-item-container.out-of-stock::after {
  content: "Sin Stock";
  position: absolute;
  top: 10px;
  right: 10px;
  background: red;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

.product-item-container.vacation-mode {
  background: #00000099;
  filter: grayscale(100%);
}

.product-actions-container {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.product-action-label {
  margin-bottom: 5px;
  color: #e0e0e0;
}

.product-action-input {
  width: 50px;
  padding: 5px;
  border: 1px solid #28a745;
  border-radius: 4px;
  box-sizing: border-box;
  background-color: #3a3a3a;
  color: #e0e0e0;
}

.product-action-button {
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.product-action-button.add {
  background-color: #2b9434;
  color: white;
}

.product-action-button.add:hover {
  background-color: #0d571e;
}

.product-action-button.delete {
  background-color: #dc3545;
  color: white;
}

.product-action-button.delete:hover {
  background-color: #8b1823;
}

.ball-card-container {
  /* background: #2e2e2e; */
  padding: 10px;
  border-radius: 10px;
  text-align: center;
  color: #e0e0e0;
  cursor: pointer;
}

.ball-card-image {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.ball-card-title {
  margin-top: 10px;
  font-size: 1.2em;
}

.ball-card-price {
  margin-top: 5px;
  font-size: 1em;
}

@media (max-width: 1024px) {
  .product-item-container {
    width: calc(33.333% - 40px); /* 3 products per row */
  }
}

@media (max-width: 768px) {
  .product-item-container {
    width: calc(50% - 20px); /* 2 products per row */
  }

  .modal-content-container {
    width: 90%;
    max-width: none;
  }
}

@media (max-width: 480px) {
  .product-item-container {
    width: 90%; /* 1 product per row */
  }

  .category-buttons-container {
    flex-direction: column;
  }

  .category-button {
    width: 100%;
    margin-bottom: 5px;
  }

  .dropdown-content-container {
    position: static;
    width: 100%;
  }

  .dropdown-content-button {
    width: 100%;
    text-align: left;
  }

  .modal-content-container {
    width: 90%;
    max-width: none;
  }
}


/* Products.css */

.hidden-checkbox-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.hidden-checkbox-label {
  color: #e0e0e0;
}

.product-item-container.hidden {
  opacity: 0.5;
}

.product-item-container.hidden::after {
  content: "Producto ocultado";
  position: absolute;
  top: 10px;
  left: 10px;
  background: #ffa500;
  color: white;
  padding: 5px 10px;
  border-radius: 5px;
}

