/* Estilos generales */
.history {
  text-align: center;
  margin: 20px;
  padding-top:25px ;
  
}

.admin-title input, .title-input {
  font-size: 1.5em;
  text-align: center;
  border: none;
  border-bottom: 2px solid #000;
  outline: none;
  width: 100%;
  max-width: 800px;
  margin-bottom: 20px;
  
}

.admin-controls {
  margin-top: 20px;
  display: flex;
  justify-content: center;
  gap: 10px;

}

.admin-controls button {
  margin: 0 10px;
  
}

.history-content {
  max-width: 800px;
  margin: 0 auto;
}

.history-item {
  margin-bottom: 20px;
  position: relative;
}

.history-item .remove-button {
  position: absolute;
  top: 0;
  right: 0;
  background: red!important;
  color: white;
  border: none;
  cursor: pointer;
  z-index: 95;
  

}

.history-image img {
  max-width: 100%;
  height: auto;
  margin-top: 20px;
}

.text-area {
  width: 100%;
  height: 150px;
  margin-top: 10px;
  font-size: 1em;
}

.save-button {
  margin-top: 20px;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
}

.save-button:hover {
  background-color: #45a049;
}

.file-input {
  width: 100%;
  box-sizing: border-box;
  margin-top: 10px;
 
  
}

/* Estilos para pantallas móviles */
@media (max-width: 600px) {
  .admin-title input, .title-input {
    width: 100%;
    box-sizing: border-box;
  }

  .text-area {
    width: 100%;
    box-sizing: border-box;
  }

  .history-item input[type="file"] {
    width: 100%;
    box-sizing: border-box;
  }

  .admin-controls {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .admin-controls button {
    width: 100%;
    margin-top: 10px;
  }

  .history-image img {
    max-width: 100%;
  }
}
