.inventario {
  margin: 20px auto;
  padding-top: 25px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-sizing: border-box;
  max-width: 800px;
  text-align: center;
}

.inventario h2 {
  margin-bottom: 20px;
  color: #f0f0f0;
}

.chart-container {
  width: 100%;
  max-width: 600px;
  margin: 20px auto;
}

.inventory-table-container {
  width: 100%;
  overflow-x: auto;
  margin-top: 20px;
}

.inventory-table {
  width: 100%;
  border-collapse: collapse;
}

.inventory-table th, .inventory-table td {
  padding: 10px;
  border: 1px solid #444;
  text-align: center;
}

.inventory-table th {
  background-color: #2c2c2c;
}

.inventory-table tbody tr:nth-child(odd) {
  background-color: #2c2c2c;
}

.inventory-table tbody tr:nth-child(even) {
  background-color: #3a3a3a;
}

.inventory-table tfoot tr {
  background-color: #2c2c2c;
  font-weight: bold;
}

.sort-buttons {
  margin: 20px 0;
}

.sort-btn {
  margin-right: 10px;
  padding: 10px 20px;
  cursor: pointer;
  background-color: #444;
  color: #e0e0e0;
  border: none;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.sort-btn:hover {
  background-color: #555;
}

.sort-btn.active {
  background-color: #666;
}

/* Responsive styles */
@media (max-width: 768px) {
  .chart-container {
    max-width: 100%;
  }

  .inventory-table-container {
    font-size: 0.8em;
  }

  .sort-btn {
    padding: 5px 10px;
    margin-bottom: 10px;
    display: block;
    width: 100%;
    box-sizing: border-box;
  }
}
