/* RenamedHistorySales.css */
.renamed-sales-report {
  margin: 20px auto;
  padding-top: 25px;
  background-color: #1e1e1e;
  color: #e0e0e0;
  box-sizing: border-box;
  max-width: 1200px;
}

.renamed-sales-report h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #f0f0f0;
}

/* Estilos para las pestañas */
.renamed-tabs {
  display: flex;
  justify-content: center;
  margin-bottom: 20px;
}

.tab-button {
  background-color: #2c2c2c;
  color: #e0e0e0;
  border: none;
  padding: 10px 20px;
  cursor: pointer;
  transition: background-color 0.3s;
}

.tab-button:hover {
  background-color: #3a3a3a;
}

.tab-button.active {
  background-color: #3498db;
  color: white;
}

.renamed-filters {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 10px;
  margin-bottom: 20px;
}

.renamed-filter-input {
  padding: 10px;
  border-radius: 5px;
  border: 1px solid #444;
  background-color: #2c2c2c;
  color: #e0e0e0;
  width: 200px;
  flex: 1 1 200px;
}

.renamed-filter-input::placeholder {
  color: #aaa;
}

.renamed-sales-section {
  margin-bottom: 20px;
  padding: 10px;
  border-radius: 10px;
  background-color: #2c2c2c;
}

.renamed-sales-section h3 {
  color: #f0f0f0;
  margin-bottom: 10px;
}

.renamed-sales-section ul {
  list-style-type: none;
  padding: 0;
}

.renamed-sales-section li {
  padding: 10px;
  background-color: #2c2c2c;
  border: 1px solid #444;
  margin-bottom: 5px;
  border-radius: 5px;
  transition: opacity 0.3s, background-color 0.3s;
}

.renamed-sales-section li.cancelled-sale {
  opacity: 0.5;
  background-color: #00000099;
}

.renamed-sales-section li:hover {
  background-color: #3a3a3a;
}

.renamed-sale-summary {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.renamed-details-button,
.cancel-sale-button,
.cancelled-sale-button {
  background-color: #3498db;
  color: white;
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  border-radius: 4px;
  margin-top: 10px;
  transition: background-color 0.3s;
}

.renamed-details-button:hover,
.cancel-sale-button:hover {
  background-color: #2980b9;
}

.cancelled-sale-button {
  background-color: #7f8c8d;
  cursor: default;
}

.renamed-order-details,
.renamed-sale-details {
  margin-top: 10px;
  background-color: #333;
  padding: 10px;
  border-radius: 5px;
}

.renamed-product-item {
  padding: 10px 0;
  border-bottom: 1px solid #555;
}

.renamed-product-separator {
  border: none;
  border-top: 1px solid #444;
  margin: 10px 0;
}

.renamed-product-image {
  max-width: 100px;
  max-height: 100px;
  display: block;
  margin-top: 5px;
}

.load-more-button {
  display: block;
  margin: 20px auto;
  padding: 10px 20px;
  background-color: #3498db;
  color: white;
  border: none;
  cursor: pointer;
  border-radius: 4px;
  font-size: 16px;
  transition: background-color 0.3s;
}

.load-more-button:hover {
  background-color: #2980b9;
}

@media (max-width: 768px) {
  .renamed-sales-report {
    padding: 15px;
  }

  .renamed-tabs {
    flex-direction: column;
  }

  .tab-button {
    width: 100%;
    margin-bottom: 5px;
  }

  .renamed-filter-input {
    width: 100%;
    margin-bottom: 10px;
  }

  .renamed-sales-section {
    padding: 5px;
  }

  .renamed-sales-section li {
    padding: 5px;
  }

  .renamed-product-item {
    flex-direction: column;
    align-items: flex-start;
  }

  .renamed-product-image {
    max-width: 80px;
    max-height: 80px;
  }

  .load-more-button {
    width: 100%;
    padding: 10px;
  }
}

@media print {
  body {
    -webkit-print-color-adjust: exact !important;
    print-color-adjust: exact !important;
    color: black !important;
    background: none !important;
  }

  .renamed-tabs,
  .renamed-filters,
  .renamed-details-button,
  .cancel-sale-button,
  .cancelled-sale-button,
  .load-more-button {
    display: none !important;
  }

  .renamed-sales-report {
    padding: 0 !important;
    margin: 0 !important;
    width: 100% !important;
    color: black !important;
    background: none !important;
  }

  .renamed-sales-section {
    page-break-inside: avoid !important;
  }

  .renamed-order-details,
  .renamed-sale-details {
    margin-top: 10px !important;
    padding: 10px !important;
    border-radius: 5px !important;
    color: black !important;
    background: none !important;
  }

  .renamed-sales-section li {
    background: none !important;
    border: 1px solid black !important;
    color: black !important;
  }

  .renamed-sales-section h3,
  .renamed-sales-section h4 {
    color: black !important;
  }

  .renamed-product-item {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid black !important;
  }

  .renamed-product-separator {
    display: none !important;
  }

  .renamed-product-image {
    max-width: 100px !important;
    max-height: 100px !important;
    display: block;
    margin-top: 5px;
  }
}