.shipping-cost-config {
    text-align: center;
    margin: 25px;
    padding-top: 30px;
}

.shipping-cost-config-title {
    font-size: 26px;
    margin-bottom: 25px;
    color: #333;
}

.shipping-cost-form {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 25px;
}

.shipping-cost-input {
    width: 55%;
    margin-bottom: 12px;
    padding: 12px;
    border: 1px solid #bbb;
    border-radius: 6px;
    text-align: center;
    font-size: 16px;
}

.shipping-cost-button {
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 12px 24px;
    cursor: pointer;
    margin-bottom: 12px;
    font-size: 16px;
}

.shipping-cost-button:hover {
    background-color: #218838;
}

.shipping-cost-list {
    margin-top: 25px;
    padding: 25px;
    background-color: #f8f9fa;
    color: #5a5a5a;
    border-radius: 6px;
}

.shipping-cost-list-title {
    font-size: 22px;
    margin-bottom: 15px;
}

.shipping-cost-item {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 12px;
    padding: 12px;
    background-color: #ececec;
    border: 1px solid #bbb;
    border-radius: 10px;
}

.shipping-cost-detail {
    margin: 6px 0;
    font-size: 15px;
}

.shipping-cost-delete-button {
    background-color: #dc3545;
    color: white;
    border: none;
    border-radius: 6px;
    padding: 6px 12px;
    cursor: pointer;
    margin-top: 12px;
    font-size: 14px;
}

.shipping-cost-delete-button:hover {
    background-color: #c82333;
}
