/* Navbar.css */
.navbar {
  background-color: #000000;
  padding: 10px 0; /* Ajustado para mejorar la apariencia */
  position: fixed;
  width: 100%;
  top: 0;
  left: 0;
  z-index: 99;
}

.navbar-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 20px; /* Añadido padding horizontal */
}

.logo img {
  height: 26px;
  transform: translateX(500%);
  transition: transform 2s;
}

.logo img.loaded {
  transform: translateX(0);
}

.logo img:hover {
  transform: scale(1.1);
}

.nav-links {
  list-style: none;
  display: flex;
  gap: 20px;
  margin: 0;
  padding: 0;
  transition: transform 0.3s ease-in-out;
}

.nav-links li {
  position: relative;
}

.nav-links a {
  color: white;
  text-decoration: none;
  font-size: 1rem;
  padding: 5px 10px;
  transition: color 0.3s;
}

.nav-links a:hover {
  color: #61dafb;
}

.nav-links a::after {
  content: '';
  display: block;
  width: 0;
  height: 2px;
  background: #61dafb;
  transition: width 0.3s;
}

.nav-links a:hover::after {
  width: 100%;
}

.hamburger {
  display: none;
  flex-direction: column;
  cursor: pointer;
}

.hamburger .line {
  width: 25px;
  height: 3px;
  background: white;
  margin: 3px 0;
}

.cart-info {
  color: #61dafb;
  border-radius: 50%;
  font-size: 14px;
}

@media (max-width: 768px) {
  .navbar-container {
    justify-content: space-between; /* Centra el contenido */
    padding: 0 10px; /* Reduce el padding para pantallas pequeñas */
  }

  .logo {
    position: static;
    transform: none;
  }

  .nav-links {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100%;
    width: 50%; /* Ajustado para mejor vista en móviles */
    background-color: rgba(0, 0, 0, 0.8);
    flex-direction: column;
    align-items: center;
    gap: 0;
    opacity: 0;
    visibility: hidden;
    z-index: 99;
    transition: right 0.3s ease-in-out, opacity 0.3s ease-in-out;
    padding: 60px 15px 15px;
  }

  .nav-links.open {
    right: 0;
    opacity: 1;
    visibility: visible;
  }

  .nav-links li {
    width: 100%;
    border-bottom: 1px solid rgba(255, 255, 255, 0.2);
  }

  .nav-links li:last-child {
    border-bottom: none;
  }

  .nav-links a {
    display: block;
    width: 100%;
    text-align: center;
    padding: 15px 0;
    color: white;
    text-decoration: none;
    font-size: 1rem;
    transition: color 0.3s;
  }

  .nav-links a:hover {
    color: #61dafb;
  }

  .hamburger {
    display: flex;
    z-index: 100;
  }
}
