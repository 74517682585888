/* Login.css */
.login-container {
  max-width: 400px;
  margin: 50px auto;
  padding: 20px;
  border-radius: 10px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: #ffffff;
  text-align: center;
  min-height: 60vh;
}

.login-title {
  font-size: 2em;
  margin-bottom: 20px;
  color: #333333;
}

.login-form {
  display: flex;
  flex-direction: column;
}

.login-input {
  font-size: 1em;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 5px;
  border: 1px solid #ccc;
  outline: none;
  width: 100%;
}

.login-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  cursor: pointer;
  border-radius: 5px;
  font-size: 1em;
}

.login-button:hover {
  background-color: #45a049;
}

.login-error {
  color: red;
  margin-top: 10px;
}

@media (max-width: 768px) {
  .login-container {
    margin: 20px;
    padding: 15px;
  }

  .login-title {
    font-size: 1.5em;
  }

  .login-input {
    font-size: 0.9em;
    padding: 8px;
  }

  .login-button {
    padding: 8px 16px;
    font-size: 0.9em;
  }
}
