.success, .failure, .pending {
    text-align: center;
    margin: 20px auto;
    padding: 20px;
    border-radius: 10px;
    width: 80%;
    max-width: 600px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  }
  
  .success h1, .failure h1, .pending h1 {
    font-size: 2em;
    margin-bottom: 10px;
  }
  
  .success p, .failure p, .pending p {
    font-size: 1.2em;
  }
  
  .success {
    background-color: #e0f7e9;
    color: #2e7d32;
  }
  
  .failure {
    background-color: #ffebee;
    color: #c62828;
  }
  
  .pending {
    background-color: #fff3e0;
    color: #f57c00;
  }
  