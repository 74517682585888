.delivery-note {
  width: 100%;
  padding: 0.5cm;
  margin: 0 auto;
  box-sizing: border-box;
  background-color: transparent !important;
  color: #000 !important;
}

.page {
  page-break-after: always;
  background-color: transparent !important;
  color: #000 !important;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 0.5cm;
  border: solid 1px #000;
  border-radius: 15px;
  padding: 15px;
  margin-top: 20px;
  background-color: transparent !important;
  color: #000 !important;
}

.logo {
  width: 330px;
  z-index: 9999 !important;
}

.logo-large {
  width: 50%;
  z-index: 9999 !important;
}

.centered-logo {
  display: block;
  margin: 0 auto 1cm;
  z-index: 9999 !important;
}

.company-info {
  text-align: right;
  font-size: 0.8em;
  color: #000 !important;
}

.order-info {
  display: grid;
  grid-template-columns: 1fr 2fr;
  gap: 3px;
  margin-bottom: 0.5cm;
  margin-top: 30px;
  font-size: 0.8em;
  color: #000 !important;
}

.info-item, .info-value {
  border: 1px solid #020202;
  padding: 5px;
  background-color: transparent !important;
  color: #000 !important;
}

.info-item {
  font-weight: bold;
}

.products-info {
  font-size: 0.8em;
  color: #000 !important;
}

.products-grid {
  display: grid;
  grid-template-columns: 1fr;
  gap: 3px;
  color: #000 !important;
}

.product-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid #444;
  border-radius: 5px;
  padding: 3px;
  background-color: transparent !important;
  color: #000 !important;
}

.product-info p {
  margin: 2px 0;
  background-color: transparent !important;
  color: #000 !important;
}

.shipping-page {
  width: 100%;
  background-color: transparent !important;
  color: #000 !important;
}

.shipping-label {
  border: 3px solid #000;
  padding: 0.5cm;
  background-color: transparent !important;
  box-sizing: border-box;
  width: 100%;
  margin: 5cm auto 0;
  color: #000 !important;
}

.shipping-details-container {
  display: flex;
  justify-content: space-between;
  background-color: transparent !important;
  color: #000 !important;
}

.shipping-details {
  width: 50%;
  font-size: 0.8em;
  margin-bottom: 0.5cm;
  background-color: transparent !important;
  color: #000 !important;
}

.shipping-field {
  border: 1px solid #030303;
  padding: 5px;
  margin-bottom: 5px;
  background-color: transparent !important;
  color: #000 !important;
}

@media print {
  body * {
    visibility: hidden;
  }

  .print-only, .print-only * {
    visibility: visible;
  }

  .print-only {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }

  .delivery-note, .shipping-label {
    border: none;
    box-shadow: none;
    margin: 0;
    padding: 0;
  }

  .header, .order-info, .products-info, .shipping-details {
    margin-bottom: 0;
    background-color: transparent !important;
    color: #000 !important;
  }

  .product-item {
    padding: 2px;
    background-color: transparent !important;
    color: #000 !important;
  }

  .product-info p {
    margin: 1px 0;
    background-color: transparent !important;
    color: #000 !important;
  }

  .page {
    page-break-after: always;
  }
}
